$(document).ready(function(){
    $('[data-toggle="popover"]').popover({
		html: true,
		placement: function (ref) {
			var pos = $(this.$element).offset();
			var rect = this.$element[0].getBoundingClientRect();
			var wh = $(window).height();
			if (rect.top > wh / 2) {
				return "top";
			}
			return "bottom";
		}
	});	
});